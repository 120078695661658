<template>
  <svg
    width="39"
    height="27"
    viewBox="0 0 39 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.3066 0.771947C39.261 1.76971 39.2258 3.35223 38.2281 4.30661L15.2281 26.3066C14.2459 27.246 12.6932 27.2288 11.7322 26.2678L0.732233 15.2678C-0.244078 14.2915 -0.244078 12.7085 0.732233 11.7322C1.70854 10.7559 3.29146 10.7559 4.26777 11.7322L13.5388 21.0033L34.7719 0.693399C35.7697 -0.26098 37.3522 -0.225813 38.3066 0.771947Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>
